define('ember-intl/formatters/-base', ['exports', 'ember', 'ember-intl/utils/array-to-hash'], function (exports, _ember, _emberIntlUtilsArrayToHash) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var get = _ember['default'].get;
  var emberString = _ember['default'].String;
  var EmberObject = _ember['default'].Object;
  var camelize = emberString.camelize;

  var FormatBase = EmberObject.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.options = (0, _emberIntlUtilsArrayToHash['default'])(this.constructor.supportedOptions);
    },

    /**
    * Filters out all of the whitelisted formatter options
    *
    * @method filterSupporedOptions
    * @param {Object} Options object
    * @return {Object} Options object containing just whitelisted options
    * @private
    */
    filterSupporedOptions: function filterSupporedOptions() {
      var input = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var out = {};

      var foundMatch = false;
      var camelizedKey = undefined;

      for (var key in input) {
        camelizedKey = camelize(key);
        if (this.options[camelizedKey]) {
          foundMatch = true;
          out[camelizedKey] = input[key];
        }
      }

      if (foundMatch) {
        return out;
      }
    },

    format: function format() {
      throw new Error('not implemented');
    },

    /**
    * Invokes the Intl formatter methods
    *
    * @method _format
    * @param {value} Raw input value that needs formatting
    * @return {Object} Formatter options hash
    * @return {Object} Format options hash
    * @private
    */
    _format: function _format(value) {
      var formatterOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var formatOptions = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var formatter = get(this, 'formatter');
      var locale = formatterOptions.locale;

      if (!locale) {
        throw new Error('No locale specified.  This is typically done application-wide within routes/application.js. Instructions: https://github.com/yahoo/ember-intl/blob/master/README.md#configure-application-wide-locale');
      }

      return formatter(locale, formatterOptions).format(value, formatOptions);
    }
  });

  FormatBase.reopenClass({
    supportedOptions: ['locale', 'format'],
    concatenatedProperties: ['supportedOptions']
  });

  exports['default'] = FormatBase;
});