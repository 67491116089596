define('ember-intl/services/intl', ['exports', 'ember', 'ember-getowner-polyfill', 'intl-messageformat', 'intl-relativeformat', 'ember-intl/utils/extend'], function (exports, _ember, _emberGetownerPolyfill, _intlMessageformat, _intlRelativeformat, _emberIntlUtilsExtend) {
  /* global requirejs */

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  var makeArray = _ember['default'].makeArray;
  var _get = _ember['default'].get;
  var set = _ember['default'].set;
  var RSVP = _ember['default'].RSVP;
  var Service = _ember['default'].Service;
  var Evented = _ember['default'].Evented;
  var logger = _ember['default'].Logger;

  var TRANSLATION_PATH_CAPTURE = /\/translations\/(.+)$/;

  function formatterProxy(formatType) {
    return function (value) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var formats = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

      var owner = (0, _emberGetownerPolyfill['default'])(this);
      var formatter = owner.lookup('ember-intl@formatter:format-' + formatType);

      if (typeof options.format === 'string') {
        options = (0, _emberIntlUtilsExtend['default'])(this.getFormat(formatType, options.format), options);
      }

      if (!options.locale) {
        options.locale = _get(this, '_locale');
      }

      if (!formats) {
        formats = _get(this, 'formats');
      }

      return formatter.format(value, options, formats);
    };
  }

  var IntlService = Service.extend(Evented, {
    _locale: null,

    locale: computed('_locale', {
      set: function set() {
        throw new Error('Use `setLocale` to change the application locale');
      },
      get: function get() {
        return _get(this, '_locale');
      }
    }),

    adapter: computed({
      get: function get() {
        return (0, _emberGetownerPolyfill['default'])(this).lookup('ember-intl@adapter:default');
      }
    }),

    formats: computed({
      get: function get() {
        var formats = (0, _emberGetownerPolyfill['default'])(this).resolveRegistration('formats:main');

        if (_ember['default'].Object.detect(formats)) {
          return formats.create();
        }

        return formats;
      }
    }),

    formatHtmlMessage: formatterProxy('html-message'),
    formatRelative: formatterProxy('relative'),
    formatMessage: formatterProxy('message'),
    formatNumber: formatterProxy('number'),
    formatTime: formatterProxy('time'),
    formatDate: formatterProxy('date'),

    t: function t(key, options, formats) {
      var translation = this.findTranslationByKey(key, options && options.locale);

      return this.formatMessage(translation, options, formats);
    },

    exists: function exists(key, optionalLocales) {
      var _this = this;

      var locales = optionalLocales;

      if (!optionalLocales) {
        locales = _get(this, '_locale');
      }

      assert('ember-intl: locale is unset, cannot lookup \'' + key + '\'', locales);

      return makeArray(locales).some(function (locale) {
        return _get(_this, 'adapter').has(locale, key);
      });
    },

    getLocalesByTranslations: function getLocalesByTranslations() {
      return Object.keys(requirejs.entries).reduce(function (translations, module) {
        var match = module.match(TRANSLATION_PATH_CAPTURE);

        if (match) {
          translations.addObject(match[1]);
        }

        return translations;
      }, _ember['default'].A());
    },

    /**
    * A utility method for registering CLDR data for
    * intl-messageformat and intl-relativeformat.  This data is derived
    * from formatjs-extract-cldr-data
    *
    * @method addLocaleData
    * @param {Object} locale data
    * @public
    */
    addLocaleData: function addLocaleData(data) {
      _intlMessageformat['default'].__addLocaleData(data);
      _intlRelativeformat['default'].__addLocaleData(data);
    },

    addTranslation: function addTranslation(locale, key, value) {
      return this.translationsFor(locale).then(function (localeInstance) {
        return localeInstance.addTranslation(key, value);
      });
    },

    addTranslations: function addTranslations(locale, payload) {
      return this.translationsFor(locale).then(function (localeInstance) {
        return localeInstance.addTranslations(payload);
      });
    },

    createLocale: function createLocale(locale, payload) {
      logger.warn('`createLocale` is deprecated, use `addTranslations`');

      return this.addTranslations(locale, payload);
    },

    setLocale: function setLocale(locales) {
      set(this, '_locale', makeArray(locales));
      this.trigger('localeChanged');
    },

    getFormat: function getFormat(formatType, format) {
      var formats = _get(this, 'formats');

      if (formats && formatType && typeof format === 'string') {
        return _get(formats, formatType + '.' + format);
      }

      return {};
    },

    translationsFor: function translationsFor(locale) {
      var result = _get(this, 'adapter').translationsFor(locale);

      return RSVP.cast(result).then(function (localeInstance) {
        if (typeof localeInstance === 'undefined') {
          throw new Error('\'locale\' must be a string or a locale instance');
        }

        return localeInstance;
      });
    },

    findTranslationByKey: function findTranslationByKey(key, locales) {
      locales = locales || _get(this, '_locale');

      var translation = _get(this, 'adapter').findTranslationByKey(makeArray(locales), key);

      if (typeof translation === 'undefined') {
        var missingMessage = (0, _emberGetownerPolyfill['default'])(this).resolveRegistration('util:intl/missing-message');
        translation = missingMessage.call(this, key, locales);
      }

      return translation;
    }
  });

  exports['default'] = IntlService;
});