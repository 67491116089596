define('ember-models-table/components/models-table', ['exports', 'ember', 'ember-models-table/utils/fmt'], function (exports, _ember, _emberModelsTableUtilsFmt) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  /**
   * @typedef {object} ModelsTableColumn
   * @property {string} propertyName data's property shown in the current column
   * @property {string} title column's title
   * @property {string} template custom template used in the column's cells
   * @property {string} sortedBy custom data's property that is used to sort column
   * @property {string} sorting is column sorted now
   * @property {boolean} isHidden is column hidden now
   * @property {boolean} mayBeHidden may this column be hidden
   * @property {boolean} filterWithSelect should select-box be used as filter for this column
   * @property {string[]|number[]} predefinedFilterOptions list of option to the filter-box (used if <code>filterWithSelect</code> is true)
   * @property {string} className custom classnames for column
   * @property {function} filterFunction custom function used to filter rows (used if <code>filterWithSelect</code> is false)
   */

  var keys = Object.keys;

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var getWithDefault = _ember['default'].getWithDefault;
  var setProperties = _ember['default'].setProperties;
  var getProperties = _ember['default'].getProperties;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var isNone = _ember['default'].isNone;
  var A = _ember['default'].A;
  var on = _ember['default'].on;
  var defineProperty = _ember['default'].defineProperty;
  var compare = _ember['default'].compare;
  var typeOf = _ember['default'].typeOf;
  var S = _ember['default'].String;
  var O = _ember['default'].Object;

  var defaultMessages = {
    searchLabel: 'Search:',
    'columns-title': 'Columns',
    'columns-showAll': 'Show All',
    'columns-hideAll': 'Hide All',
    'columns-restoreDefaults': 'Restore Defaults',
    tableSummary: 'Show %@ - %@ of %@',
    allColumnsAreHidden: 'All columns are hidden. Use <strong>columns</strong>-dropdown to show some of them',
    noDataToShow: 'No records to show'
  };

  var defaultIcons = {
    'sort-asc': 'glyphicon glyphicon-triangle-bottom',
    'sort-desc': 'glyphicon glyphicon-triangle-top',
    'column-visible': 'glyphicon glyphicon-check',
    'column-hidden': 'glyphicon glyphicon-unchecked',
    'nav-first': 'glyphicon glyphicon-chevron-left',
    'nav-prev': 'glyphicon glyphicon-menu-left',
    'nav-next': 'glyphicon glyphicon-menu-right',
    'nav-last': 'glyphicon glyphicon-chevron-right',
    'caret': 'caret'
  };

  var defaultCssClasses = {
    outerTableWrapper: 'models-table-wrapper',
    innerTableWrapper: 'inner-table-wrapper',
    table: 'table table-striped table-bordered table-condensed',
    globalFilterWrapper: 'pull-left',
    columnsDropdownWrapper: 'pull-right columns-dropdown',
    columnsDropdownButtonWrapper: 'btn-group',
    columnsDropdown: 'dropdown-menu pull-right',
    theadCell: 'table-header',
    tfooterWrapper: 'table-footer clearfix',
    footerSummary: 'table-summary',
    footerSummaryNumericPagination: 'col-md-3 col-sm-3',
    footerSummaryDefaultPagination: 'col-md-8 col-sm-8',
    pageSizeWrapper: 'col-md-2 col-sm-2',
    pageSizeSelectWrapper: 'pull-right',
    paginationWrapper: 'table-nav',
    paginationWrapperNumeric: 'col-md-7 col-sm-7',
    paginationWrapperDefault: 'col-md-2 col-sm-2',
    buttonDefault: 'btn btn-default',
    noDataCell: ''
  };

  /**
   * Extend <code>customs</code>-object with properties from <code>defaults</code>-object
   *
   * @param {object} customs
   * @param {object} defaults
   * @returns {object}
   */
  function smartExtend(customs, defaults) {
    var result = {};
    keys(customs).forEach(function (k) {
      set(result, k, get(customs, k));
    });

    keys(defaults).forEach(function (k) {
      if (isNone(get(result, k))) {
        set(result, k, get(defaults, k));
      }
    });
    return result;
  }

  /**
   * Default filter-function used in the filter by columns
   *
   * @param {string} cellValue value in the table cell
   * @param {string} filterString needed substring
   * @returns {boolean}
   */
  function defaultFilter(cellValue, filterString) {
    return -1 !== cellValue.indexOf(filterString);
  }

  /**
   * data -> filteredContent -> arrangedContent -> visibleContent
   */
  exports['default'] = _ember['default'].Component.extend({

    /**
     * Number of records shown on one table-page (size of the <code>visibleContent</code>)
     *
     * @type {number}
     */
    pageSize: 10,

    /**
     * @type {number}
     */
    currentPageNumber: 1,

    /**
     * @type {string[]}
     */
    sortProperties: A([]),

    /**
     * Determines if multi-columns sorting should be used
     *
     * @type {boolean}
     */
    multipleColumnsSorting: true,

    /**
     * Determines if table footer should be shown on the page
     *
     * @type {boolean}
     */
    showTableFooter: true,

    /**
     * Determines if numeric pagination should be used
     *
     * @type {boolean}
     */
    useNumericPagination: false,

    /**
     * Determines if columns-dropdown should be shown
     *
     * @type {boolean}
     */
    showColumnsDropdown: true,

    /**
     * Determines if filtering by columns should be available to the user
     *
     * @type {boolean}
     */
    useFilteringByColumns: true,

    /**
     * @type {string}
     */
    filterString: '',

    /**
     * Determines if filtering (global and by column) should ignore case
     *
     * @type {boolean}
     */
    filteringIgnoreCase: false,

    /**
     * Determines if "Global filter"-field should be shown
     *
     * @type {boolean}
     */
    showGlobalFilter: true,

    /**
     * All table records
     *
     * @type {Ember.Object[]}
     */
    data: A([]),

    /**
     * Table columns
     *
     * @type {ModelsTableColumn[]}
     */
    columns: A([]),

    /**
     * @type {Ember.Object[]}
     */
    processedColumns: A([]),

    /**
     * @type {Object}
     */
    messages: O.create({}),

    /**
     * @type {Object}
     */
    classes: O.create({}),

    /**
     * @type {Object}
     */
    icons: O.create({}),

    /**
     * Template with First|Prev|Next|Last buttons
     *
     * @type {string}
     */
    simplePaginationTemplate: 'components/models-table/simple-pagination',

    /**
     * Template with nav buttons
     *
     * @type {string}
     */
    numericPaginationTemplate: 'components/models-table/numeric-pagination',

    /**
     * Template with table footer
     *
     * @type {string}
     */
    tableFooterTemplate: 'components/models-table/table-footer',

    /**
     * Template with global filter
     *
     * @type {string}
     */
    globalFilterTemplate: 'components/models-table/global-filter',

    /**
     * Template with columns dropdown
     *
     * @type {string}
     */
    columnsDropdownTemplate: 'components/models-table/columns-dropdown',

    /**
     * Template for table's row
     *
     * @type {string}
     */
    rowTemplate: 'components/models-table/row',

    /**
     * True if all processedColumns are hidden by <code>isHidden</code>
     *
     * @type {boolean}
     */
    allColumnsAreHidden: computed('processedColumns.@each.isHidden', function () {
      var processedColumns = get(this, 'processedColumns');
      return processedColumns.length > 0 && processedColumns.isEvery('isHidden', true);
    }),

    /**
     * Number of pages
     *
     * @type {number}
     */
    pagesCount: computed('arrangedContent.[]', 'pageSize', function () {
      var pagesCount = get(this, 'arrangedContent.length') / get(this, 'pageSize');
      return 0 === pagesCount % 1 ? pagesCount : Math.floor(pagesCount) + 1;
    }),

    /**
     * List of links to the page
     * Used if <code>useNumericPagination</code> is true
     *
     * @type {{isLink: boolean, label: string, isActive: boolean}[]}
     */
    visiblePageNumbers: computed('arrangedContentLength', 'pagesCount', 'currentPageNumber', function () {
      var _getProperties = getProperties(this, 'pagesCount', 'currentPageNumber');

      var pagesCount = _getProperties.pagesCount;
      var currentPageNumber = _getProperties.currentPageNumber;

      var notLinkLabel = '...';
      var groups = []; // array of 8 numbers
      var labels = A([]);
      groups[0] = 1;
      groups[1] = Math.min(1, pagesCount);
      groups[6] = Math.max(1, pagesCount);
      groups[7] = pagesCount;
      groups[3] = Math.max(groups[1] + 1, currentPageNumber - 1);
      groups[4] = Math.min(groups[6] - 1, currentPageNumber + 1);
      groups[2] = Math.floor((groups[1] + groups[3]) / 2);
      groups[5] = Math.floor((groups[4] + groups[6]) / 2);

      for (var n = groups[0]; n <= groups[1]; n++) {
        labels[n] = n;
      }
      var userGroup2 = groups[4] >= groups[3] && groups[3] - groups[1] > 1;
      if (userGroup2) {
        labels[groups[2]] = notLinkLabel;
      }
      for (var i = groups[3]; i <= groups[4]; i++) {
        labels[i] = i;
      }
      var userGroup5 = groups[4] >= groups[3] && groups[6] - groups[4] > 1;
      if (userGroup5) {
        labels[groups[5]] = notLinkLabel;
      }
      for (var i = groups[6]; i <= groups[7]; i++) {
        labels[i] = i;
      }
      return A(labels.compact().map(function (label) {
        return {
          label: label,
          isLink: label !== notLinkLabel,
          isActive: label === currentPageNumber };
      }));
    }),

    /**
     * Are buttons "Back" and "First" enabled
     *
     * @type {boolean}
     */
    gotoBackEnabled: computed.gt('currentPageNumber', 1),

    /**
     * Are buttons "Next" and "Last" enabled
     *
     * @type {boolean}
     */
    gotoForwardEnabled: computed('currentPageNumber', 'pagesCount', function () {
      return get(this, 'currentPageNumber') < get(this, 'pagesCount');
    }),

    /**
     * @type {Ember.Object[]}
     */
    filteredContent: computed('filterString', 'data.[]', 'useFilteringByColumns', 'processedColumns.@each.filterString', function () {
      var _getProperties2 = getProperties(this, 'processedColumns', 'data', 'useFilteringByColumns', 'filteringIgnoreCase');

      var processedColumns = _getProperties2.processedColumns;
      var data = _getProperties2.data;
      var useFilteringByColumns = _getProperties2.useFilteringByColumns;
      var filteringIgnoreCase = _getProperties2.filteringIgnoreCase;

      var filterString = get(this, 'filterString');

      if (!data) {
        return A([]);
      }

      // global search
      var globalSearch = data.filter(function (row) {
        return processedColumns.length ? processedColumns.any(function (c) {
          var propertyName = get(c, 'propertyName');
          if (propertyName) {
            var cellValue = '' + get(row, propertyName);
            if (filteringIgnoreCase) {
              cellValue = cellValue.toLowerCase();
              filterString = filterString.toLowerCase();
            }
            return -1 !== cellValue.indexOf(filterString);
          }
          return false;
        }) : true;
      });

      if (!useFilteringByColumns) {
        return A(globalSearch);
      }

      // search by each column
      return A(globalSearch.filter(function (row) {
        return processedColumns.length ? processedColumns.every(function (c) {
          var propertyName = get(c, 'propertyName');
          if (propertyName) {
            var cellValue = '' + get(row, propertyName);
            if (get(c, 'useFilter')) {
              var filterString = get(c, 'filterString');
              if (get(c, 'filterWithSelect')) {
                if ('' === filterString) {
                  return true;
                }
                return 0 === compare(cellValue, filterString);
              } else {
                if (filteringIgnoreCase) {
                  cellValue = cellValue.toLowerCase();
                  filterString = filterString.toLowerCase();
                }
                return c.filterFunction(cellValue, filterString);
              }
            }
            return true;
          }
          return true;
        }) : true;
      }));
    }),

    /**
     * @type {Ember.Object[]}
     */
    arrangedContent: computed.sort('filteredContent', 'sortProperties'),

    /**
     * Content of the current table page
     *
     * @type {Ember.Object[]}
     */
    visibleContent: computed('arrangedContent.[]', 'pageSize', 'currentPageNumber', function () {
      var _getProperties3 = getProperties(this, 'arrangedContent', 'pageSize', 'currentPageNumber');

      var arrangedContent = _getProperties3.arrangedContent;
      var pageSize = _getProperties3.pageSize;
      var currentPageNumber = _getProperties3.currentPageNumber;

      var startIndex = pageSize * (currentPageNumber - 1);
      if (get(arrangedContent, 'length') < pageSize) {
        return arrangedContent;
      }
      return A(arrangedContent.slice(startIndex, startIndex + pageSize));
    }),

    /**
     * Real table summary
     *
     * @type {string}
     */
    summary: computed('firstIndex', 'lastIndex', 'arrangedContentLength', function () {
      var _getProperties4 = getProperties(this, 'arrangedContentLength', 'firstIndex', 'lastIndex');

      var arrangedContentLength = _getProperties4.arrangedContentLength;
      var firstIndex = _getProperties4.firstIndex;
      var lastIndex = _getProperties4.lastIndex;

      return (0, _emberModelsTableUtilsFmt['default'])(get(this, 'messages.tableSummary'), firstIndex, lastIndex, arrangedContentLength);
    }),

    /**
     * Is user on the last page
     *
     * @type {boolean}
     */
    isLastPage: computed.not('gotoForwardEnabled'),

    /**
     * Alias to <code>arrangedContent.length</code>
     *
     * @type {number}
     */
    arrangedContentLength: computed.alias('arrangedContent.length'),

    /**
     * Index of the first currently shown record
     *
     * @type {number}
     */
    firstIndex: computed('arrangedContentLength', 'pageSize', 'currentPageNumber', function () {
      var _getProperties5 = getProperties(this, 'currentPageNumber', 'pageSize', 'arrangedContentLength');

      var currentPageNumber = _getProperties5.currentPageNumber;
      var pageSize = _getProperties5.pageSize;
      var arrangedContentLength = _getProperties5.arrangedContentLength;

      return 0 === arrangedContentLength ? 0 : pageSize * (currentPageNumber - 1) + 1;
    }),

    /**
     * Index of the last shown record
     *
     * @type {number}
     */
    lastIndex: computed('isLastPage', 'arrangedContentLength', 'currentPageNumber', 'pageSize', function () {
      var _getProperties6 = getProperties(this, 'currentPageNumber', 'pageSize', 'isLastPage', 'arrangedContentLength');

      var currentPageNumber = _getProperties6.currentPageNumber;
      var pageSize = _getProperties6.pageSize;
      var isLastPage = _getProperties6.isLastPage;
      var arrangedContentLength = _getProperties6.arrangedContentLength;

      return isLastPage ? arrangedContentLength : currentPageNumber * pageSize;
    }),

    /**
     * List of possible <code>pageSize</code> values
     * Used to change size of <code>visibleContent</code>
     *
     * @type {number[]}
     */
    pageSizeValues: A([10, 25, 50]),

    /**
     * Open first page if user has changed pageSize
     * @method pageSizeObserver
     */
    pageSizeObserver: observer('pageSize', function () {
      set(this, 'currentPageNumber', 1);
    }),

    /**
     * Open first page if user has changed filterString
     *
     * @method filterStringObserver
     */
    filterStringObserver: observer('filterString', 'processedColumns.@each.filterString', function () {
      set(this, 'currentPageNumber', 1);
    }),

    /**
     * @method contentChangedAfterPolling
     */
    contentChangedAfterPolling: function contentChangedAfterPolling() {
      get(this, 'filteredContent');
      this.notifyPropertyChange('filteredContent');
    },

    /**
     * Component init
     * Set visibility and filtering attributes for each column
     * Update messages used by table with user-provided messages (@see messages)
     * Update icons used by table with user-provided icons (@see icons)
     * Update classes used by table with user-provided css-classes (@see classes)
     *
     * @method setup
     */
    setup: on('init', function () {
      this._setupColumns();
      this._setupMessages();
      this._setupIcons();
      this._setupClasses();
    }),

    /**
     * Create new properties for <code>columns</code> (filterString, useFilter, isVisible, defaultVisible)
     *
     * @method _setupColumns
     * @private
     */
    _setupColumns: function _setupColumns() {
      var self = this;
      var nColumns = A(get(this, 'columns').map(function (column) {
        var filterFunction = get(column, 'filterFunction');
        filterFunction = 'function' === typeOf(filterFunction) ? filterFunction : defaultFilter;

        var c = O.create(JSON.parse(JSON.stringify(column)));
        var propertyName = get(c, 'propertyName');
        if (isNone(get(c, 'filterString'))) {
          setProperties(c, {
            filterString: '',
            useFilter: !isNone(propertyName)
          });
        }
        set(c, 'filterFunction', filterFunction);

        if (isNone(get(c, 'mayBeHidden'))) {
          set(c, 'mayBeHidden', true);
        }

        defineProperty(c, 'isVisible', computed.not('isHidden'));
        defineProperty(c, 'sortAsc', computed.equal('sorting', 'asc'));
        defineProperty(c, 'sortDesc', computed.equal('sorting', 'desc'));

        setProperties(c, {
          defaultVisible: !get(c, 'isHidden'),
          sorting: 'none'
        });

        if (get(c, 'filterWithSelect') && get(c, 'useFilter')) {
          var predefinedFilterOptions = get(column, 'predefinedFilterOptions');
          if (predefinedFilterOptions && predefinedFilterOptions.length && '' !== predefinedFilterOptions[0]) {
            predefinedFilterOptions = [''].concat(predefinedFilterOptions);
          }
          var usePredefinedFilterOptions = 'array' === typeOf(predefinedFilterOptions);
          set(c, 'filterOptions', usePredefinedFilterOptions ? predefinedFilterOptions : []);
          if (!usePredefinedFilterOptions) {
            self.addObserver('data.@each.' + propertyName, self, self._updateFiltersWithSelect);
          }
        }
        return c;
      }));

      nColumns.forEach(function (column) {
        if (isNone(get(column, 'propertyName'))) {
          return;
        }
        var propertyName = get(column, 'propertyName');
        if (isNone(get(column, 'title'))) {
          set(column, 'title', self._propertyNameToTitle(propertyName));
        }
        self.addObserver('data.@each.' + propertyName, self, self.contentChangedAfterPolling);
      });
      set(this, 'processedColumns', nColumns);
      this._updateFiltersWithSelect();
    },

    /**
     * Convert some string to the human readable one
     *
     * @method _propertyNameToTitle
     * @param {string} name value to convert
     * @return {string}
     * @private
     */
    _propertyNameToTitle: function _propertyNameToTitle(name) {
      return S.capitalize(S.dasherize(name).replace(/\-/g, ' '));
    },

    /**
     * Update messages used by widget with custom values provided by user in the <code>customMessages</code>
     *
     * @method _setupMessages
     * @private
     */
    _setupMessages: function _setupMessages() {
      var customIcons = getWithDefault(this, 'customMessages', {});
      var newMessages = smartExtend(customIcons, defaultMessages);
      set(this, 'messages', O.create(newMessages));
    },

    /**
     * Update icons-classes used by widget with custom values provided by user in the <code>customIcons</code>
     *
     * @method _setupIcons
     * @private
     */
    _setupIcons: function _setupIcons() {
      var customIcons = getWithDefault(this, 'customIcons', {});
      var newIcons = smartExtend(customIcons, defaultIcons);
      set(this, 'icons', O.create(newIcons));
    },

    /**
     * Update css-classes used by widget with custom values provided by user in the <code>customClasses</code>
     *
     * @method _setupClasses
     * @private
     */
    _setupClasses: function _setupClasses() {
      var customClasses = getWithDefault(this, 'customClasses', {});
      var newClasses = smartExtend(customClasses, defaultCssClasses);
      set(this, 'classes', O.create(newClasses));
    },

    /**
     * Updates <code>filterOptions</code> for columns which use <code>filterWithSelect</code>
     * and don't have <code>predefinedFilterOptions</code>
     * <code>filterOptions</code> are calculated like <code>data.mapBy(column.propertyName).uniq()</code>,
     * where data is component's <code>data</code>
     * If preselected option doesn't exist after <code>filterOptions</code> update,
     * <code>filterString</code> is reverted to empty string (basically, filtering for this column is dropped)
     *
     * @private
     */
    _updateFiltersWithSelect: function _updateFiltersWithSelect() {
      var _this = this;

      var processedColumns = get(this, 'processedColumns');
      var data = get(this, 'data');
      processedColumns.forEach(function (column) {
        var predefinedFilterOptions = get(column, 'predefinedFilterOptions');
        var filterWithSelect = get(column, 'filterWithSelect');
        if (filterWithSelect && 'array' !== typeOf(predefinedFilterOptions)) {
          var propertyName = get(column, 'propertyName');
          var filterOptions = [''].concat(A(A(data.filterBy(propertyName)).mapBy(propertyName)).uniq());
          var filterString = get(column, 'filterString');
          if (-1 === filterOptions.indexOf(filterString)) {
            _this.$('.changeFilterForColumn.' + propertyName).val(''); // select empty value
            set(column, 'filterString', '');
          }
          set(column, 'filterOptions', filterOptions);
        }
      });
    },

    /**
     * Set <code>sortProperties</code> when single-column sorting is used
     *
     * @param {ModelsTableColumn} column
     * @param {string} sortedBy
     * @param {string} newSorting 'asc|desc|none'
     * @method _singleColumnSorting
     * @private
     */
    _singleColumnSorting: function _singleColumnSorting(column, sortedBy, newSorting) {
      get(this, 'processedColumns').setEach('sorting', 'none');
      set(column, 'sorting', newSorting);
      if ('none' === newSorting) {
        set(this, 'sortProperties', []);
      } else {
        set(this, 'sortProperties', [sortedBy + ':' + newSorting]);
      }
    },

    /**
     * Set <code>sortProperties</code> when multi-columns sorting is used
     *
     * @param {ModelsTableColumn} column
     * @param {string} sortedBy
     * @param {string} newSorting 'asc|desc|none'
     * @method _multiColumnsSorting
     * @private
     */
    _multiColumnsSorting: function _multiColumnsSorting(column, sortedBy, newSorting) {
      set(column, 'sorting', newSorting);
      var sortProperties = get(this, 'sortProperties');
      var sortPropertiesMap = {};
      sortProperties.forEach(function (p) {
        var _p$split = p.split(':');

        var _p$split2 = _slicedToArray(_p$split, 2);

        var propertyName = _p$split2[0];
        var order = _p$split2[1];

        sortPropertiesMap[propertyName] = order;
      });
      delete sortPropertiesMap[sortedBy];

      var newSortProperties = A([]);
      keys(sortPropertiesMap).forEach(function (propertyName) {
        if (propertyName !== sortedBy) {
          newSortProperties.pushObject(propertyName + ':' + sortPropertiesMap[propertyName]);
        }
      });
      if ('none' !== newSorting) {
        newSortProperties.pushObject(sortedBy + ':' + newSorting);
      }
      set(this, 'sortProperties', newSortProperties);
    },

    actions: {

      sendAction: function sendAction() {
        this.sendAction.apply(this, arguments);
      },

      toggleHidden: function toggleHidden(column) {
        if (get(column, 'mayBeHidden')) {
          column.toggleProperty('isHidden');
        }
      },

      showAllColumns: function showAllColumns() {
        get(this, 'processedColumns').setEach('isHidden', false);
      },

      hideAllColumns: function hideAllColumns() {
        get(this, 'processedColumns').setEach('isHidden', true);
      },

      restoreDefaultVisibility: function restoreDefaultVisibility() {
        get(this, 'processedColumns').forEach(function (c) {
          set(c, 'isHidden', !get(c, 'defaultVisible'));
        });
      },

      gotoFirst: function gotoFirst() {
        if (!get(this, 'gotoBackEnabled')) {
          return;
        }
        set(this, 'currentPageNumber', 1);
      },

      gotoPrev: function gotoPrev() {
        if (!get(this, 'gotoBackEnabled')) {
          return;
        }
        if (get(this, 'currentPageNumber') > 1) {
          this.decrementProperty('currentPageNumber');
        }
      },

      gotoNext: function gotoNext() {
        if (!get(this, 'gotoForwardEnabled')) {
          return;
        }
        var currentPageNumber = get(this, 'currentPageNumber');
        var pageSize = get(this, 'pageSize');
        var arrangedContentLength = get(this, 'arrangedContent.length');
        if (arrangedContentLength > pageSize * (currentPageNumber - 1)) {
          this.incrementProperty('currentPageNumber');
        }
      },

      gotoLast: function gotoLast() {
        if (!get(this, 'gotoForwardEnabled')) {
          return;
        }
        var pageSize = get(this, 'pageSize');
        var arrangedContentLength = get(this, 'arrangedContent.length');
        var pageNumber = arrangedContentLength / pageSize;
        pageNumber = 0 === pageNumber % 1 ? pageNumber : Math.floor(pageNumber) + 1;
        set(this, 'currentPageNumber', pageNumber);
      },

      gotoCustomPage: function gotoCustomPage(pageNumber) {
        set(this, 'currentPageNumber', pageNumber);
      },

      /**
       * @param {ModelsTableColumn} column
       */
      sort: function sort(column) {
        var sortMap = {
          none: 'asc',
          asc: 'desc',
          desc: 'none'
        };
        var sortedBy = get(column, 'sortedBy') || get(column, 'propertyName');
        if (isNone(sortedBy)) {
          return;
        }
        var currentSorting = get(column, 'sorting');
        var newSorting = sortMap[currentSorting];

        if (get(this, 'multipleColumnsSorting')) {
          this._multiColumnsSorting(column, sortedBy, newSorting);
        } else {
          this._singleColumnSorting(column, sortedBy, newSorting);
        }
      },

      changePageSize: function changePageSize() {
        var selectedIndex = this.$('.changePageSize')[0].selectedIndex;
        var pageSizeValues = get(this, 'pageSizeValues');
        var selectedValue = pageSizeValues[selectedIndex];
        set(this, 'pageSize', selectedValue);
      },

      /**
       * @param {ModelsTableColumn} column
       */
      changeFilterForColumn: function changeFilterForColumn(column) {
        var val = this.$('.changeFilterForColumn.' + get(column, 'propertyName'))[0].value;
        set(column, 'filterString', val);
      }

    }

  });
});