define('ember-intl/formatters/format-relative', ['exports', 'ember', 'intl-relativeformat', 'intl-format-cache', 'ember-intl/formatters/-base'], function (exports, _ember, _intlRelativeformat, _intlFormatCache, _emberIntlFormattersBase) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;

  function assertIsDate(date, errMsg) {
    assert(errMsg, isFinite(date));
  }

  var FormatRelative = _emberIntlFormattersBase['default'].extend({
    formatType: 'relative',

    formatter: computed({
      get: function get() {
        return (0, _intlFormatCache['default'])(_intlRelativeformat['default']);
      }
    }).readOnly(),

    format: function format(value) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var dateValue = new Date(value);

      assertIsDate(dateValue, 'A date or timestamp must be provided to format-relative');

      return this._format(dateValue, this.filterSupporedOptions(options), {
        now: options.now
      });
    }
  });

  FormatRelative.reopenClass({
    supportedOptions: ['style', 'units']
  });

  exports['default'] = FormatRelative;
});