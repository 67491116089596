define('ember-cli-notifications/components/notification-message', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['c-notification'],
    classNameBindings: ['processedType', 'notification.dismiss::c-notification--in', 'notification.onClick:c-notification--clickable'],

    paused: false,

    // Set the correct close icon depending on chosen icon font
    closeIcon: _ember['default'].computed('icons', function () {
      if (this.get('icons') === 'bootstrap') return 'glyphicon glyphicon-remove';

      return 'fa fa-times';
    }),

    // Set icon depending on notification type
    notificationIcon: _ember['default'].computed('notification.type', 'icons', function () {
      var icons = this.get('icons');

      if (icons === 'bootstrap') {
        switch (this.get('notification.type')) {
          case "info":
            return 'glyphicon glyphicon-info-sign';
          case "success":
            return 'glyphicon glyphicon-ok-sign';
          case "warning":
          case "error":
            return 'glyphicon glyphicon-exclamation-sign';
        }
      }

      switch (this.get('notification.type')) {
        case "info":
          return 'fa fa-info-circle';
        case "success":
          return 'fa fa-check';
        case "warning":
          return 'fa fa-warning';
        case "error":
          return 'fa fa-exclamation-circle';
      }
    }),

    mouseDown: function mouseDown() {
      if (this.get('notification.onClick')) {
        this.get('notification.onClick')(this.get('notification'));
      }
    },
    mouseEnter: function mouseEnter() {
      if (this.get('notification.autoClear')) {
        this.set('paused', true);
        this.notifications.pauseAutoClear(this.get('notification'));
      }
    },

    mouseLeave: function mouseLeave() {
      if (this.get('notification.autoClear')) {
        this.set('paused', false);
        this.notifications.setupAutoClear(this.get('notification'));
      }
    },

    processedType: _ember['default'].computed('notification.type', function () {
      if (this.get('notification.type') && _ember['default'].A(['info', 'success', 'warning', 'error']).contains(this.get('notification.type'))) {
        return 'c-notification--' + this.get('notification.type');
      }
    }),

    // Apply the clear animation duration rule inline
    notificationClearDuration: _ember['default'].computed('paused', 'notification.clearDuration', function () {
      var duration = _ember['default'].Handlebars.Utils.escapeExpression(this.get('notification.clearDuration'));
      var playState = this.get('paused') ? 'paused' : 'running';
      return _ember['default'].String.htmlSafe('animation-duration: ' + duration + 'ms; -webkit-animation-duration: ' + duration + 'ms; animation-play-state: ' + playState + '; -webkit-animation-play-state: ' + playState);
    }),

    actions: {
      removeNotification: function removeNotification() {
        this.notifications.removeNotification(this.get('notification'));
      }
    }
  });
});