define('ember-cli-mirage/utils/read-modules', ['exports'], function (exports) {
  /* global Ember, requirejs, require */
  /*jslint node: true */

  'use strict';

  /*
    This function looks through all files that have been loaded by Ember CLI and
    finds the ones under /mirage/[factories, fixtures, scenarios, models]/, and exports
    a hash containing the names of the files as keys and the data as values.
  */

  exports['default'] = function (prefix) {
    var modules = ['factories', 'fixtures', 'scenarios', 'models', 'serializers'];
    var mirageModuleRegExp = new RegExp('^' + prefix + '/mirage/(' + modules.join("|") + ')');
    var modulesMap = modules.reduce(function (memo, name) {
      memo[name] = {};
      return memo;
    }, {});

    _.keys(requirejs._eak_seen).filter(function (key) {
      return mirageModuleRegExp.test(key);
    }).forEach(function (moduleName) {
      if (moduleName.match('.jshint')) {
        // ignore autogenerated .jshint files
        return;
      }
      var moduleParts = moduleName.split('/');
      var moduleType = moduleParts[moduleParts.length - 2];
      var moduleKey = moduleParts[moduleParts.length - 1];
      Ember.assert('Subdirectories under ' + moduleType + ' are not supported', moduleParts[moduleParts.length - 3] === 'mirage');
      if (moduleType === 'scenario') {
        Ember.assert('Only scenario/default.js is supported at this time.', moduleKey !== 'default');
      }

      var module = require(moduleName, null, null, true);
      if (!module) {
        throw new Error(moduleName + ' must export a ' + moduleType);
      }

      var data = module['default'];

      modulesMap[moduleType][moduleKey] = data;
    });

    return modulesMap;
  };
});