define('ember-intl/models/translation', ['exports', 'ember'], function (exports, _ember) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  var TranslationModel = _ember['default'].Object.extend({
    /**
     * Add a single translation
     */
    addTranslation: function addTranslation(key, value) {
      if (typeof this[key] !== 'function') {
        set(this, key, value);
      }
    },

    /**
     * Adds a translation hash
     */
    addTranslations: function addTranslations(messageObject) {
      for (var key in messageObject) {
        if (messageObject.hasOwnProperty(key)) {
          this.addTranslation(key, messageObject[key]);
        }
      }
    },

    /**
     * Custom accessor hook that can be overridden.
     * This would enable consumers that have dot notated strings
     * to implement this function as `return this[key];`
     */
    getValue: function getValue(key) {
      return get(this, key);
    },

    /**
     * Determines if the translation model contains a key
     */
    has: function has(key) {
      return typeof this.getValue(key) === 'string';
    }
  });

  exports['default'] = TranslationModel;
});