define('ember-models-table/helpers/is-equal', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.isEqual = isEqual;

  function isEqual(params /*, hash*/) {
    return params;
  }

  exports['default'] = _ember['default'].Helper.helper(isEqual);
});