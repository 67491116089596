define('ember-mousetrap/mixins/mousetrap-route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    mergedProperties: ['shortcuts'],

    mousetrapBindKeys: _ember['default'].on('activate', function () {
      if (!this.shortcuts) {
        return;
      }

      Object.keys(this.shortcuts).forEach(function (key) {
        var callback = this.shortcuts[key];

        if (!callback.__ember_mousetrap__) {
          return;
        }

        var keys = callback.__ember_mousetrap__.keys;
        var action = callback.__ember_mousetrap__.action;

        Mousetrap.bind(keys, callback.bind(this), action);
      }, this);
    }),

    mousetrapUnbindKeys: _ember['default'].on('deactivate', function () {
      if (!this.shortcuts) {
        return;
      }

      Object.keys(this.shortcuts).forEach(function (key) {
        var callback = this.shortcuts[key];

        if (!callback.__ember_mousetrap__) {
          return;
        }

        var keys = callback.__ember_mousetrap__.keys;

        Mousetrap.unbind(keys);
      }, this);
    })
  });
});